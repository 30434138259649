import Container from 'react-bootstrap/Container';

export function NotFoundPage() {
    return (
        <main>
            <Container>
                <div>
                    <h2>404 Страница не найдена</h2>
                </div>
            </Container>
        </main>
    );
}